export const paths = [
  "overview",
  "products",
  "news",
  "cooperation",
  "development",
  // "passport",
  "info",
  "faq",
  "notFound"
] as const;
export type Paths = typeof paths[number];

export interface LinkValue {
  label: string;
  paths: string[];
  isHidden?: boolean;
}

type MenuLinks = Record<Paths, LinkValue>;

export const menuLinks: MenuLinks = {
  overview: {
    label: "Overview",
    paths: ["/"],
    isHidden: false
  },
  products: {
    label: "Products",
    paths: [
      "/products",
      "/products/blockchain",
      "/products/skey-access-control",
      "/products/skey-box",
      "/products/skey-tix"
    ],
    isHidden: false
  },
  news: {
    label: "News",
    paths: ["/news"],
    isHidden: false
  },
  cooperation: {
    label: "Cooperation",
    paths: ["/cooperation"],
    isHidden: false
  },
  development: {
    label: "Development",
    paths: ["/development"],
    isHidden: false
  },
  // passport: {
  //   label: "Passport",
  //   paths: ["/passport"],
  //   isHidden: false
  // },
  info: {
    label: "Info",
    paths: ["/info"],
    isHidden: true
  },
  faq: {
    label: "FAQ",
    paths: ["/faq"],
    isHidden: false
  },
  notFound: {
    label: "Not Found",
    paths: ["/*"],
    isHidden: true
  }
};
