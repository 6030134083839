import { Container } from "react-bootstrap";

import { SKEY_STAKING } from "../../../../constants";
import { menuLinks } from "../../../../data/menuLinks";

import Group from "../../../../components/arrangement/Group/Group";
import MenuBar from "../../molecules/MenuBar/MenuBar";
import Image from "../../atoms/Image/Image";
import Link from "../../atoms/Link/Link";

import "./Navbar.scss";

const Navbar = () => {
  return (
    <>
      <div className="navbar-background"></div>
      <section id="nav-bar">
        <Container fluid="xl">
          <Group fullSize justifyContent="space-between">
            <Group colGap={64}>
              <Image
                imgWidth={104}
                imgHeight={36}
                imgPath={"/images/skey-logo-basic.svg"}
                alt={"skey logo"}
                asLink={true}
                href={menuLinks.overview.paths[0]}
              />
              <Link
                imgHeight={35}
                imgWidth={121}
                url={SKEY_STAKING}
                icon="/images/skey-staking-logo.svg"
                variant="trietary"
                className="p-0 d-xs-n d-lg-b"
                iconOnLeft
              >
                {""}
              </Link>
            </Group>
            <Group justifyContent="space-between" fullHeight colGap={10} className="w-auto">
              <MenuBar />
            </Group>
          </Group>
        </Container>
      </section>
    </>
  );
};

export default Navbar;
